import React, { Component } from 'react'

export default class Video extends Component {
  state = {
    jsonP: '//fast.wistia.com/embed/medias/i57wx8frny.jsonp',
    script: '//fast.wistia.com/assets/external/E-v1.js'
  }

  componentDidMount() {
    this.createScript(this.state.jsonP)
    this.createScript(this.state.script)
  }

  createScript = url => {
    let script = document.createElement('script')
    script.src = url
    script.async = true

    document.body.appendChild(script)
  }

  render() {
    const { sizes } = this.props

    return <>
      <div
        className="video wistia_embed wistia_async_i57wx8frny"
        style={sizes ? sizes : { height: '349px', width: '620px' }}
      />
      <div>90 seconds video about Ally</div>
    </>;
  }
}
