import React from 'react'

function onRequestDemo(e) {
  e.preventDefault();
  // eslint-disable-next-line
  Intercom('showNewMessage', 'Hi, I would like to request a demo.');
}

const RequestDemo = () => (
  <section className="request-demo row">
    <div className="request-demo__item row">
      <div className="request-demo__image col-xs-12 col-sm-8">
        <h1 className="heading heading--big">
          Ready to get started?
          </h1>
        <p>Spend less time juggling tabs and more time focusing on meaningful work.</p>
      </div>
      <div className="highlights__text col-xs-12 col-sm-4">
        <p>
          <a
            href="/"
            className="hero-main__btn btn btn--blue btn--big"
            onClick={onRequestDemo}
          >
            Request a demo
            </a>
        </p>
      </div>
    </div>
  </section>
)

export default RequestDemo
