import React, { Component } from 'react'
import PropTypes from 'prop-types'
import withSizes from 'react-sizes'

import Image from '../../components/Image/Image'

class Highlights extends Component {
  componentDidMount() {
    this.setState({ width: window.innerWidth })
  }

  render() {
    const { isMobile, items, startsLeft } = this.props

    const generateDesktopItems = () => {
      const direction = startsLeft ? 0 : 1

      return items.map(({ heading, subheading, imageName }, index) => {
        if (index % 2 === direction) {
          return (
            <div className="highlights__item row" key={index}>
              <div className="highlights__text col-xs-12 col-sm-6">
                <p className="heading heading--sm">{heading}</p>
                <p>{subheading}</p>
              </div>
              <div className="highlights__image col-xs-12 col-sm-6">
                <Image
                  filename={imageName}
                  alt="Visibility from idea to production"
                />
              </div>
            </div>
          )
        } else {
          return (
            <div className="highlights__item row" key={index}>
              <div className="highlights__image col-xs-12 col-sm-6">
                <Image
                  filename={imageName}
                  alt="Visibility from idea to production"
                />
              </div>
              <div className="highlights__text col-xs-12 col-sm-6">
                <p className="heading heading--sm">{heading}</p>
                <p>{subheading}</p>
              </div>
            </div>
          )
        }
      })
    }

    const generateMobileItems = () => {
      return items.map(
        ({ heading, subheading, imageName, imageMobileName }, i) => (
          <div className="highlights__item row" key={i}>
            <div className="highlights__text col-xs-12">
              <p className="heading heading--sm">{heading}</p>
              <p>{subheading}</p>
            </div>
            <div className="highlights__image highlights__image--full col-xs-12">
              <Image
                filename={
                  imageMobileName
                    ? imageMobileName
                    : imageName.replace('.png', '-mobile.png')
                }
                alt="Fully-cusomizable dashboards"
              />
            </div>
          </div>
        )
      )
    }

    if (!isMobile && !this.state) {
      console.log('Both isMobile and state are null')
      return <section className="highlights" />
    }

    if (!isMobile) {
      return (
        <section className="highlights">
          <h2 className="highlights__heading heading heading--md">
            Highlights
          </h2>

          {generateDesktopItems()}
        </section>
      )
    } else {
      return (
        <section className="highlights highlights--mobile">
          <h2 className="highlights__heading heading heading--md">
            Highlights
          </h2>

          {generateMobileItems()}
        </section>
      )
    }
  }
}
Highlights.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  startsLeft: PropTypes.bool
}

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 792
})

export default withSizes(mapSizesToProps)(Highlights)
