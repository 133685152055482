import React from 'react'
import PropTypes from 'prop-types'

import Image from '../../components/Image/Image'
import Video from '../../components/Video/Video'

import Companies from '../../components/Companies/Companies'

const Showcase = ({ imageName }) => (
  <section className="showcase row">
    <div className="showcase__image col-xs-12">
      <Image
        filename={imageName ? imageName : 'home-showcase'}
        alt="Showcase"
      />
    </div>
    <Companies className="col-xs-12 hidden-mobile" />

    <div className="col-xs-12 visible-only-mobile">
      <p>Trusted by</p>
      <Companies className="col-xs-12" />
      <Video sizes={{ height: '167px', width: '297px' }} />
    </div>
  </section>
)

Showcase.propTypes = {
  imageName: PropTypes.string
}

export default Showcase
