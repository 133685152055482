import React from 'react'
import PropTypes from 'prop-types'

import Video from '../../components/Video/Video'
import Image from '../../components/Image/Image'

const Hero = props => {
  const { isSolutions, heading, subheading, group, features, imageName } = props

  if (!isSolutions) {
    return (
      <section className="hero-main row">
        <div className="hero-main__text col-xs-12 col-sm-6">
          <h1 className="heading heading--big">
            Connect your tools with Ally
          </h1>
          <p>
            Spend less time juggling tabs and more time focusing on meaningful work.
          </p>
          <a
            href="https://app.getally.com"
            className="hero-main__btn btn btn--blue btn--big"
          >
            Get Started
          </a>
          <ul className="hero-main__list list list--marks">
            <li>Free 14-day trial</li>
            <li>Easy setup</li>
            <li>No card required</li>
          </ul>
        </div>
        <div className="hero-main__video col-xs-12 col-sm-6 hidden-mobile">
          <Video />
        </div>
      </section>
    )
  } else {
    return (
      <section className="hero-main row">
        <div className="hero-main__text col-xs-12 col-sm-7">
          <h1 className="heading heading--big">{heading}</h1>
          <p className="hero-main__subheading">{subheading}</p>
          <p className="small bold grey">Learn how {group} use Ally:</p>
          <ul className="hero-main__features-list">
            {features.map((item, i) => (
              <li className="hero-main__feature" key={i}>
                {item}
              </li>
            ))}
          </ul>
          <a
            href="https://app.getally.com"
            className="hero-main__btn btn btn--blue btn--big"
          >
            Get Started
          </a>
        </div>
        <div className="hero-main__image col-xs-12 col-sm-5 hidden-mobile">
          <Image filename={imageName} alt="workflow" />
        </div>
      </section>
    )
  }
}

Hero.propTypes = {
  isSolutions: PropTypes.bool,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  group: PropTypes.string,
  features: PropTypes.arrayOf(PropTypes.string),
  imageName: PropTypes.string
}

export default Hero
